h3 {
  font-size: 1.4rem;
  font-weight: bold;
}

h5, h6 {
  font-size: 1rem;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 0;
}
h5 {
  text-transform: uppercase;
}
h6 {
  margin-bottom: 5px;
}

.modal-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.6);
  height: 100%;
  width: 100%;
}

.modal {
  background: white;
  padding: 20px;
  padding-top: 0;
}

.signature-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 800px;
  height: 400px;
}

.signature-canvas {
  border: 1px dashed black;
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
}

.button-row {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
}

.w-full {
  width: 100%;
}